<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="售后记录" />
			<div class="ct_box">
				<div class="search_status_box flex">
					<div class="search_box flex">
						<order_time @get_time="get_time" />
            <order_search @search_text="change_text"/>
<!--						<el-select v-model="resource.title" @change="category_id_check" placeholder="请选择">-->
<!--							<el-option v-for="item in resource_list" :key="item.id" :value="item.title">-->
<!--							</el-option>-->
<!--						</el-select>-->
					</div>
					<orderStatus width="250" :status_list="status_list" @status_tab="status_tab" :order_status="status"
						:category_id="1" />
				</div>
				<table class="table">
					<thead>
						<tr>
							<td>订单ID</td>
							<td>标题</td>
							<td>问题描述</td>
							<td>媒介名称</td>
							<td>金额</td>
							<td>状态</td>
							<td>提交时间</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="item.id">
							<td>{{ item.sn }}</td>
							<td>{{ item.title }}</td>
							<td>{{ item.desc }}</td>
							<td>{{ item.medium_title }}</td>
							<td>￥{{ item.medium_price }}</td>
							<td>
								<!-- {{item.status == 0 ? '未处理' : '已处理'}} -->
								<div class="untreated" @click="item.check = !item.check" v-if="item.status == 0">
									未处理
								</div>
								<div v-if="item.check" class="untreated_success" @click="upStatus(item, 1)">
									已处理
								</div>
								<div v-if="item.check" class="untreated_refuse" @click="upStatus(item, 2)">
									拒绝处理
								</div>
								<div class="untreated_success" v-if="item.status == 1">
									已处理
								</div>
								<div class="untreated_refuse" v-if="item.status == 2">
									拒绝处理
								</div>
							</td>
							<td>{{ item.updatetime }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>
	</div>
</div></template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import { mapMutations } from 'vuex'
import { timeCut } from '@/util/timeCut'
import order_time from '@/components/order_time'
import order_search from '@/components/order_search.vue'
import orderStatus from '@/components/orderStatus'
export default {
	components: {
		Title,
		order_time,
		order_search,
		orderStatus
	},
	mounted() {
		if (this.$route.query.time) {
			this.status = {
				id: 6,
				title: '限时订单',
			}
		} else if (this.$route.query.status && this.$route.query.status == '退稿') {
			this.status = {
				id: 5,
				title: '撤稿',
			}
		} else if (
			this.$route.query.status &&
			this.$route.query.status == '已处理'
		) {
			this.status = {
				id: 2,
				title: '已出稿',
			}
		}
		this.get_list()
	},
	data() {
		return {
			time: [],
			resource: {
				id: '',
				title: '不限',
			},
			title: '',
			resource_list: [],
			tableData: [],
			status_list: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '未处理',
				},
				{
					id: 1,
					title: '已处理',
				},
				{
					id: 2,
					title: '拒绝处理',
				}
			],
			status:'',
			Link: false, //查看链接弹窗
			order: false, //提交工单弹窗
			orderItem: {}, //工单信息
			serviceShow: false, //客服
			work: '',
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
			linkList: {},
			fullscreenLoading: false, //loding
		}
	},
	methods: {
		status_tab(id) {
			this.status = id
			this.pageChange(1)
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		statusShow(item) {
			this.status = item
			this.get_list()
		},
		get_time(time) {
			this.time = time || []
			this.pageChange(1)
		},
		change_text(text) {
			this.title = text
			this.pageChange(1)
		},
		get_list() {
			this.fullscreenLoading = true
			// 取媒体类别
			this.curlGet('/api/medium_category/list', {}).then((res) => {
				if (res.data.code) {
					this.resource_list = res.data.data
				}
			})
			let data = {}
			if (this.status.id !== '') {
				data.status = this.status.id
			}
			if (this.title != '') {
				data.search = this.title
			}
			if (this.time) {
				data.start_time = this.time[0]
				data.end_tiem = this.time[1]
			}
			this.curlGet('/api/users/work/after_sale', data).then((res) => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					this.tableData.forEach((item, index) => {
						if (item.status == 0) {
							this.$set(item, 'check', false)
						}

						/*-------------时间------------------------- */
						item.updatetime = timeCut(item.updatetime * 1000)

					})
					console.log(this.tableData)
					this.fullscreenLoading = false
				}
			})
		},
		category_id_check(val) {
			let resultArr = this.resource_list.filter((item, key) => {
				if (item.title == val) {
					this.resource.id = item.id
					this.resource.title = item.title
				}
			});
			this.get_list()
		},
		// 修改订单状态
		upStatus(item, status) {
			this.curlPost('/api/users/work/status_edit', {
				id: item.id,
				status: status,
			}).then((res) => {
				console.log(res)
				if (res.data.code) {
					this.get_list()
					return this.$message({
						message: '修改成功',
						type: 'success',
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/AfterSale';

::v-deep .el-input__inner {
	height: 30px;
}

::v-deep .el-input__icon {
	line-height: 30px;
}
</style>